<template>
  <div class="join-us max-min-width">
    <div>
      <div class="title">加入我们</div>
      <div class="box-show">
        <div class="position">
          <div class="position-demand">
            <div class="post-name">{{ lists[postIndex] }}</div>
            <pre>{{ content }}</pre>
          </div>
          <div class="position-list">
            <div class="title-text">全部职位</div>
            <div
              class="list"
              :class="[postIndex == index ? '_list' : '']"
              v-for="(item, index) in lists"
              :key="index"
              @click="changePost(item, index)"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div class="btn-vessel">
          <div class="send-post-btn" @click="toInfo">投递简历</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAllPosts, getPostJD } from "../api/index.js";
export default {
  name: "joinUs",
  data() {
    return {
      lists: [],
      content: "",
      postIndex: 0,
    };
  },
  created() {
    this.getAllPosts();
  },
  methods: {
    getAllPosts() {
      getAllPosts().then((res) => {
        this.lists = res.data;
        this.getPostJD(res.data[this.postIndex]);
      });
    },
    getPostJD(postName) {
      getPostJD({
        postName: postName,
      }).then((res) => {
        this.content = res.data;
      });
    },
    changePost(postName, index) {
      this.postIndex = index;
      this.getPostJD(postName);
    },
    toInfo() {
      this.$router.push({
        name: "add-info",
        params: {
          mark: true,
        },
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.join-us {
  background: #ffffff;
  padding: 0 120px;
  padding-top: 12px;
  padding-bottom: 100px;

  .title {
    height: 92px;
    background: #ff6915;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    text-align: center;
    line-height: 92px;
    color: #ffffff;
    font-size: 30px;
  }

  .box-show {
    box-shadow: 0px 20px 40px 0px rgba(251, 163, 105, 0.1);
    background: #ffffff;
  }

  .position {
    padding-left: 50px;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;

    .position-demand {
      width: 700px;
      // height: 520px;
      overflow-x: hidden;
      overflow-y: auto;

      .post-name {
        font-size: 20px;
        color: #25242e;
        font-weight: 600;
      }

      pre {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 26px;
        font-family: PingFangSC-Regular, PingFang SC;
        white-space: pre-wrap;
        word-wrap: break-word;
      }
    }

    .position-list {
      width: 330px;
      font-size: 20px;

      .title-text {
        color: #25242e;
        position: relative;
        font-weight: 600;

        &::after {
          content: "";
          width: 4px;
          height: 20px;
          line-height: 20px;
          background-color: #ff6915;
          position: absolute;
          top: 50%;
          left: -10px;
          transform: translateY(-50%);
        }
      }

      .list {
        font-size: 16px;
        font-weight: 400;
        color: #25242e;
        line-height: 20px;
        margin-top: 20px;
        cursor: pointer;
        font-family: PingFangSC-Regular, PingFang SC;

        &:hover {
          color: #ff6915;
        }
      }

      ._list {
        color: #ff6915;
      }
    }
  }

  .btn-vessel {
    padding: 50px 0;
  }

  .send-post-btn {
    width: 200px;
    height: 60px;
    background: #ff6915;
    border-radius: 30px;
    font-weight: 500;
    color: #ffffff;
    font-size: 16px;
    text-align: center;
    line-height: 60px;
    margin: 0 auto;
    cursor: pointer;
    user-select: none;
    transition: 0.5s;

    &:hover {
      box-shadow: 0px 10px 20px 0px rgba(251, 163, 105, 0.5);
    }
  }
}
</style>
