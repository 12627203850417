import {
  getRequest,
  postRequest
} from './axios';

// 获取菜单栏数据 
export const getAllPosts = (params) => {
  return getRequest('/api/site_back/post/getAllPosts', params)
};

export const getPostJD = (params) => {
  return getRequest('/api/site_back/post/getPostJD', params)
};

export const upload = (params) => {
  return postRequest('/api/site_back/file/upload', params)
};
  
export const send = (params) => {
  return getRequest('/api/site_back/post/apply', params)
};
