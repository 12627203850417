import axios from 'axios';
import qs from 'qs'

export const baseURL = "";

// 超时设定
axios.defaults.timeout = 150000;

// 携带cookie
axios.defaults.withCredentials = true;

axios.interceptors.request.use(config => {
  return config;
}, err => {
  console.log('请求超时');
  return Promise.resolve(err);
});
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// http response 拦截器
axios.interceptors.response.use(response => {

  return response.data;
}, (err) => {

  return Promise.resolve(err);
});

export const getRequest = (url, params, onDownloadProgress) => {
  let headers = {};
  
  return axios({
    method: 'get',
    url: `${baseURL}${url}`,
    params: params,
    onDownloadProgress(progress) {
      onDownloadProgress && onDownloadProgress(progress);
    },
    headers: headers
  });
};

export const postRequest = (url, params, onDownloadProgress) => {
  return axios({
    method: 'post',
    url: `${baseURL}${url}`,
    data: params,
    onDownloadProgress(progress) {
      onDownloadProgress && onDownloadProgress(progress);
    },
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};