<template>
  <div class="JoinUs">
    <div class="title-1">JOIN US</div>
    <div class="title-2">加入我们</div>
    <div class="tabBox">
      <div
        class="tabList"
        :class="[postIndex == index ? 'tabListActive' : '']"
        v-for="(item, index) in lists"
        :key="index"
        @click="changePost(item, index)"
      >
        <div class="jdTitle">
          <div class="title">
            {{ item }}
          </div>
          <div class="titleIcon">
            <img
              src="../../assets/mobile/up.png"
              alt=""
              v-if="postIndex == index"
            />
            <img src="../../assets/mobile/down.png" alt="" v-else />
          </div>
        </div>
        <div
          class="jdContent"
          :class="[postIndex == index ? 'jdContentShow' : '']"
        >
          <pre>{{ content }}</pre>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="content">
        BOSS直聘、拉勾、智联招聘搜索「钻木科技」，或将简历投递至hr@isee.work
      </div>
    </div>
  </div>
</template>

<script>
import { getAllPosts, getPostJD } from "../../api/index.js";
export default {
  name: "joinUs",
  data() {
    return {
      lists: [],
      content: "",
      postIndex: 0,
    };
  },
  created() {
    this.getAllPosts();
  },
  methods: {
    getAllPosts() {
      getAllPosts().then((res) => {
        this.lists = res.data;
        this.getPostJD(res.data[this.postIndex]);
      });
    },
    getPostJD(postName) {
      getPostJD({
        postName: postName,
      }).then((res) => {
        this.content = res.data;
      });
    },
    changePost(postName, index) {
      if (this.postIndex == index) {
        this.postIndex = -1;
      } else {
        this.postIndex = index;
        this.getPostJD(postName);
      }
    },
    toInfo() {
      this.$router.push({
        name: "add-info",
        params: {
          mark: true,
        },
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.JoinUs {
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  top: 0.6rem;
  background: #fff;
  .title-1 {
    color: #bababe;
    font-size: 0.1rem;
    text-align: center;
    margin-top: 0.21rem;
  }
  .title-2 {
    color: #25242e;
    font-size: 0.26rem;
    text-align: center;
    margin-top: 0.09rem;
  }
  .tabBox {
    width: 3.45rem;
    margin-top: 0.2rem;
    .tabList {
      padding: 0.24rem 0.25rem 0;
      background: #fff;
      margin-bottom: 0.24rem;
      .jdTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          color: #212128;
          font-size: 0.15rem;
        }
        .titleIcon {
          width: 0.22rem;
          height: 0.22rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .jdContent {
        color: #999999;
        font-size: 0.13rem;
        padding-bottom: 0.21rem;
        max-height: 0;
        overflow: hidden;
        -webkit-transition: max-height 0.3s;
        transition: max-height 0.3s;
        pre {
          white-space: pre-wrap;
          word-wrap: break-word;
          line-height: 0.22rem;
        }
      }
      .jdContentShow {
        max-height: 10rem;
      }
    }
    .tabListActive {
      box-shadow: 0 0.05rem 0.2rem 0 rgba(251, 163, 105, 0.2);
    }
  }
  .footer {
    width: 2.95rem;
    margin-top: 0.29rem;
    margin-bottom: 0.15rem;
    .content {
      color: #cacaca;
      font-size: 0.12rem;
      text-align: center;
    }
  }
}
</style>
