<template>
  <div class="info max-min-width">
    <div class="title">加入我们</div>
    <div
      v-if="!success"
      style="
        box-shadow: 0px 20px 40px 0px rgba(251, 163, 105, 0.1);
        padding-bottom: 20px;
      "
    >
      <div class="info-vessel">
        <div class="back" @click="backEvent">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#iconarrow-ios-forward-outline"></use>
          </svg>
          <span>返回职位列表</span>
        </div>
        <div class="info-list">
          <input placeholder="姓名" type="text" v-model="name" />
        </div>
        <div class="info-list">
          <input placeholder="电话" type="text" v-model="phone" />
        </div>
        <div class="info-list">
          <input placeholder="邮箱" type="text" v-model="email" />
          <div class="tip" v-if="check">请输入正确的邮箱格式</div>
        </div>
        <div class="info-list">
          <div class="select" @click="selectEvent">
            {{ lists[optionIndex] }}
          </div>
          <div class="options" v-show="show">
            <div
              class="option"
              v-for="(item, index) in lists"
              :key="index"
              :class="[optionIndex == index ? '_option' : '']"
              @click="selectList(item, index)"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div>
          <div class="info-list" style="margin-bottom: 0">
            <div class="file">
              <svg class="icon iconshangchuan" aria-hidden="true">
                <use xlink:href="#iconshangchuan-hover"></use>
              </svg>
              <div>点此上传简历，支持word、PDF格式</div>
            </div>
            <input class="file-input" @change="uploadFile" type="file" />
          </div>
          <div class="file-list" v-if="file.name">{{ file.name }}</div>
        </div>
      </div>

      <div class="send-btn" @click="sendEvent">确认并提交</div>
    </div>

    <div v-if="success" class="success">
      <div class="success-tip">
        您的申请已提交，我们会尽快处理，请留意您的邮箱~
      </div>
      <router-link to="/">
        <div class="home-btn">回到首页</div>
      </router-link>
      <img src="http://acc.sys-img.quhuo.net/site_front/site_logo.png" alt="" />
    </div>
  </div>
</template>

<script>
import { getAllPosts, getPostJD, upload, send } from "../api/index.js";
export default {
  data() {
    return {
      lists: [],
      optionIndex: 0,
      show: false,
      check: false,
      name: "",
      phone: "",
      email: "",
      file_token: "",
      file: {},
      success: false,
    };
  },
  name: "info",
  created() {
    console.log(this.$route.params);
    if (this.$route.params.mark) {
      this.getAllPosts();
    } else {
      this.$router.push("/join-us");
    }
  },
  watch: {
    email(val) {
      this.check = !this.emailCheck(val);
    },
  },
  methods: {
    backEvent() {
      this.$router.back();
    },
    emailCheck(value) {
      return /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/.test(
        value
      );
    },
    getAllPosts() {
      getAllPosts().then((res) => {
        this.lists = res.data;
      });
    },
    selectEvent() {
      this.show = !this.show;
    },
    selectList(item, index) {
      this.optionIndex = index;
      this.selectEvent();
    },
    uploadFile(e) {
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      upload(formData).then((res) => {
        if (res.errorCode == 0) {
          this.file_token = res.data;
          this.file = e.target.files[0];
        }
      });
    },
    sendEvent() {
      if (!this.name.trim()) {
        alert("请填写姓名");
        return;
      }
      if (!this.phone.trim()) {
        alert("请填写电话");
        return;
      }
      if (!this.email.trim()) {
        alert("请填写邮箱");
        return;
      }
      if (!this.file_token) {
        alert("请上传您的简历！");
        return;
      }
      send({
        name: this.name,
        phone: this.phone,
        email: this.email,
        post: this.lists[this.optionIndex],
        file_token: this.file_token,
      }).then((res) => {
        if (res.errorCode == 0) {
          alert("提交成功");
          this.success = true;
        }
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.info {
  background: #ffffff;
  padding: 0 120px;
  padding-top: 12px;
  padding-bottom: 100px;

  .title {
    height: 92px;
    background: #ff6915;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    text-align: center;
    line-height: 92px;
    color: #ffffff;
    font-size: 30px;
  }

  .info-vessel {
    width: 445px;
    margin: 0 auto;
    // box-shadow: 0px 20px 40px 0px rgba(251, 163, 105, 0.1);

    .back {
      color: #ff6915;
      font-size: 14px;
      display: flex;
      margin: 30px 0 25px;
      cursor: pointer;

      .icon {
        display: block;
        font-size: 20px;
        transform: rotate(180deg);
      }
    }

    .info-list {
      position: relative;
      font-size: 14px;
      margin-bottom: 33px;

      input[type="text"] {
        padding: 5px 18px;
        display: block;
        width: 445px;
        height: 40px;
        border: 1px solid #e6e6e6;
        border-radius: 10px;
        outline: none;
        font-size: 14px;
        color: #25242e;
      }

      .tip {
        color: #ff6766;
        position: absolute;
        bottom: -20px;
        left: 20px;
      }

      .select {
        width: 445px;
        height: 40px;
        line-height: 40px;
        text-indent: 20px;
        border: 1px solid #e6e6e6;
        border-radius: 10px;
        cursor: pointer;
        color: #25242e;
        font-size: 14px;
      }

      .options {
        background-color: #ffffff;
        box-shadow: 0px 20px 40px 0px rgba(45, 45, 45, 0.1);
        transition: 0.5s;
        position: absolute;
        top: 50px;
        left: 0;
        z-index: 10;
        color: #25242e;
        font-size: 14px;

        .option {
          width: 445px;
          line-height: 20px;
          height: 30px;
          padding: 5px 12px;
          cursor: pointer;

          &:hover {
            opacity: 0.8;
          }
        }

        ._option {
          background-color: #e6f7ff;
        }
      }

      .file {
        display: block;
        width: 445px;
        height: 120px;
        border-radius: 5px;
        border: 1px solid #e6e6e6;
        position: relative;
        text-align: center;
        color: #999999;
        font-size: 14px;

        .iconshangchuan {
          display: block;
          font-size: 60px;
          margin: 20px auto 5px;
        }
      }

      .file-input {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        top: 0;
        left: 0;
        cursor: pointer;
      }
    }
    .file-list {
      padding: 5px 12px;
      font-size: 14px;
      background-color: #f3f3f3;
    }
  }

  .send-btn {
    width: 200px;
    height: 60px;
    background: #ff6915;
    border-radius: 30px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    line-height: 60px;
    text-align: center;
    margin: 50px auto 30px;
    transition: 0.5s;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 10px 20px 0px rgba(251, 163, 105, 0.5);
    }
  }

  .success {
    box-shadow: 0px 20px 40px 0px rgba(251, 163, 105, 0.1);
    height: 630px;
    position: relative;

    .success-tip {
      text-align: center;
      padding: 150px 0 80px;
      color: #25242e;
      font-size: 14px;
    }

    .home-btn {
      width: 200px;
      height: 60px;
      background: #ff6915;
      border-radius: 30px;
      margin: 0 auto;
      text-align: center;
      line-height: 60px;
      color: #ffffff;
      position: relative;
      cursor: pointer;
      transition: 0.5s;

      &:hover {
        box-shadow: 0px 10px 20px 0px rgba(251, 163, 105, 0.5);
      }
    }

    img {
      position: absolute;
      width: 444px;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
</style>
